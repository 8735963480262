import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, Redirect } from "react-router-dom"
import { Col, Container, Row, Card, CardBody } from "reactstrap"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import "./index.css"
import { toast, ToastContainer } from "react-toastify"
import { FaFilter } from "react-icons/fa"

import { DateRangePicker } from "react-date-range"
import { addDays } from "date-fns"
import LineChart from "./LineChart"
import baseURL from "../../utils"

import useSWR from "swr"

const Dashboard = () => {
  const [menu, setMenu] = useState(false)
  const toggle = () => {
    setMenu(!menu)
  }
  const [redirect, setRedirect] = useState("")
  const [showDate, setShowDate] = useState(false)
  const [search, setSearch] = useState("")
  const [searchResult, setSearchResult] = useState([])
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date("2022-01-01"), 0),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ])

  const minDate = dateRange[0].startDate.toISOString().split("T")[0]
  const maxDate = dateRange[0].endDate.toISOString().split("T")[0]

  // const getTotal = async () => {
  //   try {
  //     const options = {
  //       method: "GET",
  //       headers: new Headers({ Authorization: accessToken }),
  //     }
  //     const response = await fetch(
  //       `${baseURL}/api/v1/admin/dashboard-stats?date_range=${minDate},${maxDate}`,
  //       options
  //     )
  //     const total = await response.json()
  //     console.log(total)
  //     setTotal(total.data)
  //     setLoading(false)
  //     if (response.status === 401) {
  //       setRedirect("/login")
  //     }
  //   } catch (error) {
  //     // do what
  //     console.log(error, "error")
  //     toast.error(error)
  //     setLoading(false)
  //     setRedirect("/login")
  //   }
  // }

  // useEffect(() => {
  //   getTotal()
  // }, [minDate, maxDate])

  const fetcher = url =>
    fetch(url, {
      method: "GET",
      headers: new Headers({ Authorization: accessToken }),
    }).then(res => res.json())

  const { data: total, error } = useSWR(
    `${baseURL}/api/v1/admin/dashboard-stats?date_range=${minDate},${maxDate}`,
    fetcher
  )

  if (error) {
    toast.error("Failed to load data")
    return <Redirect to="/login" />
  }

  if (!total) {
    return (
      <div className="page-content">
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={6}>
              <h2 className="dashboard-head">Dashboard Home</h2>
            </Col>
            <Col md={6}>
              <input
                type="text"
                placeholder="Search sender by name or email address"
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={9}>
              <Row className="mt-4">
                <Col md={3}>
                  <Card className="card-height">
                    <CardBody>
                      <div className="col-md-10 skeleton skeleton-p mt-2 mb-4"></div>
                      <div className="col-md-4 skeleton skeleton-head p-3 mt-2 mb-4"></div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card className="card-height">
                    <CardBody>
                      <div className="col-md-10 skeleton skeleton-p mt-2 mb-4"></div>
                      <div className="col-md-4 skeleton skeleton-head p-3 mt-2 mb-4"></div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card className="card-height">
                    <CardBody>
                      <div className="col-md-10 skeleton skeleton-p mt-2 mb-4"></div>
                      <div className="col-md-4 skeleton skeleton-head p-3 mt-2 mb-4"></div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card className="card-height">
                    <CardBody>
                      <div className="col-md-10 skeleton skeleton-p mt-2 mb-4"></div>
                      <div className="col-md-4 skeleton skeleton-head p-3 mt-2 mb-4"></div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Row>
                    <Col md={12}>
                      <Card className="card-height">
                        <CardBody>
                          <div className="col-md-5 skeleton skeleton-p mt-2 mb-4"></div>
                          <div className="col-md-3 skeleton skeleton-head p-2 mt-2 mb-4"></div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="card-height">
                        <CardBody>
                          <div className="col-md-5 skeleton skeleton-p mt-2 mb-4"></div>
                          <div className="col-md-3 skeleton skeleton-head p-2 mt-2 mb-4"></div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col md={8}>
                  <Card className="card-height">
                    <CardBody style={{ minHeight: "304px" }}>
                      <LineChart />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={3} className="mt-4">
              <Card className="card-height">
                <Link to="/all-orders">
                  <CardBody style={{ minHeight: "470px" }}>
                    <div className="col-md-6">
                      <div className="col-md-5 skeleton skeleton-head p-2 mt-2 mb-5"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="skeleton skeleton-head p-2 mt-2"></div>
                      <div className="skeleton skeleton-head p-2 mt-2"></div>
                      <div className="skeleton skeleton-head p-2 mt-2"></div>
                      <div className="skeleton skeleton-head p-2 mt-2"></div>
                      <div className="skeleton skeleton-head p-2 mt-2"></div>
                      <hr />
                      <div className="skeleton skeleton-head p-3 mt-2"></div>
                    </div>
                  </CardBody>
                </Link>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const data = total?.data;

  // Search Users
  const searchUser = async () => {
    console.log(search)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          search,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/users/search`,
        options
      )
      const data = await response.json()
      setSearchResult(data["data"]["users"])
      if (data.status == "success") {
        console.log(data["data"]["users"])
      } else if (data.status == "error") {
        toast.error(data["message"])
      }
    } catch (error) {
      // do what
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dilivva | Admin Dashboard</title>
        </MetaTags>
        <Container fluid>
          <ToastContainer />
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={6}>
                <h2 className="dashboard-head">Dashboard Home</h2>
              </Col>
              <Col md={6}>
                <input
                  type="text"
                  placeholder="Search sender by name or email address"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  onKeyUp={() => searchUser()}
                />
                <div className="list-group rounded">
                  {searchResult.map(search => {
                    const { full_name, uuid } = search
                    return (
                      <button
                        type="button"
                        className="list-group-item list-group-item-action"
                        onClick={() => setRedirect("/senders/" + uuid)}
                      >
                        {full_name}
                      </button>
                    )
                  })}
                </div>
              </Col>
            </Row>

            <>
              <Row className="mb-3">
                <Col md={2} xs={8}>
                  <Row className="">
                    <Col
                      className="sort"
                      onClick={() => setShowDate(!showDate)}
                    >
                      <FaFilter /> Filter
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  {showDate && (
                    <DateRangePicker
                      onChange={item => setDateRange([item.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={dateRange}
                      direction="horizontal"
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={9}>
                  <Row>
                    <Col md={3}>
                      <Link to="/senders">
                        <Card className="card-height">
                          <CardBody>
                            <p className="head">
                              Senders -{" "}
                              <span className="user-number">
                                {data?.user_count}
                              </span>
                            </p>
                            <h6 className="text-green">
                              Android - {data?.android_user_count}
                            </h6>
                            <h6 className="text-orange">
                              iOS - {data?.ios_user_count}
                            </h6>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                    <Col md={3}>
                      <Link to="/partners">
                        <Card className="card-height">
                          <CardBody>
                            <p className="head">
                              Partners -{" "}
                              <span className="user-number">
                                {data?.partner_count}
                              </span>
                            </p>
                            <h6 className="text-green">
                              Android - {data?.android_partner_count}
                            </h6>
                            <h6 className="text-orange">
                              iOS - {data?.ios_partner_count}
                            </h6>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                    <Col md={3}>
                      <Card className="card-height">
                        <CardBody>
                          <div>
                            <Link to="/centers" className="head">
                              Centers -{" "}
                              <span className="user-number">
                                {data?.centers_count}
                              </span>
                            </Link>
                          </div>
                          <div>
                            <Link to="/hubs" className="head">
                              Hub -{" "}
                              <span className="user-number">
                                {data?.hubs_count}
                              </span>
                            </Link>
                          </div>
                          <div>
                            <Link to="/sweepers" className="head">
                              Sweeper -{" "}
                              <span className="user-number">
                                {data?.sweepers_count}
                              </span>
                            </Link>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={3}>
                      <Card className="card-height">
                        <CardBody>
                          <Link to="/all-transactions">
                            <p className="head">Revenue</p>
                            <h2 className="user-number">
                              ₦{data?.total_revenue}
                            </h2>
                          </Link>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Row>
                        <Col md={12}>
                          <Card className="card-height">
                            <CardBody>
                              <p className="head">Paystack Balance</p>
                              <h2 className="user-number">
                                ₦{data?.paystack_balance}
                              </h2>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={12}>
                          <Card className="card-height">
                            <CardBody>
                              <p className="head">Upcoming Payments</p>
                              <h2 className="user-number">
                                ₦{data?.total_payments}
                              </h2>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={8}>
                      <Card className="card-height">
                        <CardBody style={{ minHeight: "304px" }}>
                          <LineChart />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  <Card className="card-height">
                    <Link to="/all-orders">
                      <CardBody style={{ minHeight: "470px" }}>
                        <p className="head mb-5">Packages</p>
                        <h6 className="my-4 d-flex justify-content-between align-items-center">
                          <span className="package pending">Pending</span>{" "}
                          <span className="text-warning">
                            {data?.order_status_count?.pending}
                          </span>
                        </h6>
                        <h6 className="my-4 d-flex justify-content-between align-items-center">
                          <span className="package accepted">Accepted</span>{" "}
                          <span className="text-green">
                            {data?.order_status_count?.accepted}
                          </span>
                        </h6>
                        <h6 className="my-4 d-flex justify-content-between align-items-center">
                          <span className="package transit">In Transit</span>{" "}
                          <span className="text-transit">
                            {data?.order_status_count?.transit}
                          </span>
                        </h6>
                        <h6 className="my-4 d-flex justify-content-between align-items-center">
                          <span className="package completed">Completed</span>{" "}
                          <span className="text-green">
                            {data?.order_status_count?.completed}
                          </span>
                        </h6>
                        <h6 className="my-4 d-flex justify-content-between align-items-center">
                          <span className="package cancelled">Cancelled</span>{" "}
                          <span className="text-red">
                            {data?.order_status_count?.cancelled}
                          </span>
                        </h6>
                        <hr />
                        <h6 className="my-4 d-flex justify-content-between align-items-center">
                          <span className="package total">Total</span>{" "}
                          <span className="text-dark">
                            {data?.order_status_count?.total}
                          </span>
                        </h6>
                      </CardBody>
                    </Link>
                  </Card>
                </Col>

                <Col md={3}>
                  <Card className="card-height">
                    <CardBody>
                      <p className="head">Partners Wallet Balance</p>
                      <h2 className="user-number">
                        ₦ {data?.partners_wallet_balance}
                      </h2>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card className="card-height">
                    <CardBody>
                      <p className="head">Senders Wallet Balance</p>
                      <h2 className="user-number">
                        ₦ {data?.users_wallet_balance}
                      </h2>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card className="card-height">
                    <CardBody>
                      <p className="head">Hubs Wallet Balance</p>
                      <h2 className="user-number">
                        ₦ {data?.hubs_wallet_balance}
                      </h2>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card className="card-height">
                    <CardBody>
                      <p className="head">SMS Balance</p>
                      <h2 className="user-number">₦{data?.termii_balance}</h2>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
