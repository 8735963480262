import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link, Redirect } from "react-router-dom"
import { FaArrowLeft, FaChevronLeft } from "react-icons/fa"
import baseURL from "../../utils"
import "../Orders/orders.css"
import point from "../../assets/images/point.png"
import points from "../../assets/images/point.svg"

import useSWR from "swr"

const fetcher = url =>
  fetch(url, {
    method: "GET",
    headers: new Headers({ Authorization: accessToken }),
  }).then(res => res.json())

const OrderDetails = props => {
  const [singleOrder, setSingleOrder] = useState({})
  const [_loading, setLoading] = useState(true)
  const [partners, setPartners] = useState([])
  const [sweepers, setSweepers] = useState([])
  const [buttonLoad, setButtonLoad] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [disableButtonStatus, setDisableButtonStatus] = useState(false)
  const [search, setSearch] = useState("")
  const [redirect, setRedirect] = useState("")
  const [originPartner, setOriginPartner] = useState(null)
  const [destinationPartner, setDestinationPartner] = useState(null)
  const [_location, setLocation] = useState("")
  const [showSweepers, setShowSweepers] = useState(false)
  const [changeSweepers, setChangeSweepers] = useState(true)

  const showModal = singleOrder => {
    setSingleOrder(singleOrder)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/partners`,
          options
        )
        const partners = await response.json()
        setPartners(partners.data.partners)
        setLoading(false)
        setLastPage(partners.data.meta)
      } catch (error) {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // URL for fetching order details
  const orderDetailsUrl = `${baseURL}/api/v1/admin/orders/${props?.match?.params?.id}`

  // Fetch data using SWR
  const {
    data: orderDetails,
    error: orderDetailsError,
    isLoading,
  } = useSWR(orderDetailsUrl, fetcher)

  if (orderDetailsError) {
    console.error("Failed to load order details:", orderDetailsError)
    // handle error or fallback
  }

  // Use `orderDetails` to access the fetched data
  const orders = orderDetails?.data

  const [originSweeperId, setOriginSweeperId] = useState(
    orders?.origin_sweeper?.uuid || ""
  )
  const [destinationSweeperId, setDestinationSweeperId] = useState(
    orders?.destination_sweeper?.uuid || ""
  )

  // Order location
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/orders/${props?.match?.params?.id}/location`,
          options
        )
        const location = await response.json()
        console.log(location.data, "order location")
        setLoading(false)
        setLocation(location.data)
      } catch (error) {
        console.log("error", error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // Fetch sweepers
  useEffect(() => {
    const fetchSweepers = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/sweepers?per_page=50`,
          options
        )
        const sweepers = await response.json()
        setSweepers(sweepers.data.sweepers)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    fetchSweepers()
  }, [])

  // Assgin Partner
  const assignPartner = async id => {
    console.log(id)
    if (confirm("Are you sure you want to assign this order?")) {
      // do stuff
      setButtonLoad(true)
      setDisableButton(true)
      try {
        const options = {
          method: "PUT",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            partner_id: id,
          }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/orders/${props?.match?.params?.id}/assign-partner`,
          options
        )
        const data = await response.json()
        console.log(data)
        console.log(data.message)
        toast.success(data["message"])
        setButtonLoad(false)
        setDisableButton(false)
        setTimeout(() => {
          window.location.href = "/all-orders"
        }, 5000)
      } catch (error) {
        // do what
        toast.error(data["message"])
        setButtonLoad(false)
        setDisableButton(false)
      }
    } else {
      return false
    }
  }
  // Assgin sweeper to order
  const assignSweeper = async () => {
    setChangeSweepers(true)
    setButtonLoad(true)
    try {
      const options = {
        method: "PUT",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          variant: orders?.variant,
          origin_sweeper_id: originSweeperId,
          origin_hub_id: orders?.origin_hub?.uuid,
          destination_sweeper_id: destinationSweeperId,
          destination_hub_id: orders?.destination_hub?.uuid,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/orders/${props?.match?.params?.id}/assign-sweeper`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      toast.success(data["message"])
      setButtonLoad(false)
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    } catch (error) {
      // do what
      // toast.error(data["message"])
      toast.error("Error")
      setButtonLoad(false)
      setChangeSweepers(false)
    }
  }

  // Change order Status
  const orderStatus = async () => {
    console.log(originPartner, destinationPartner)
    // do stuff
    setButtonLoad(true)
    setDisableButtonStatus(true)
    try {
      const options = {
        method: "PUT",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        body: JSON.stringify({
          origin_partner_id: originPartner,
          destination_partner_id: destinationPartner,
          variant: orders.variant,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/orders/${props?.match?.params?.id}`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      if (data.status === "success") {
        toast.success(data["message"])
      } else {
        toast.error(data["message"])
      }
      setButtonLoad(false)
      setDisableButton(false)
      setTimeout(() => {
        window.location.reload(1)
      }, 5000)
    } catch (error) {
      // do what
      toast.error(data["message"])
      setButtonLoad(false)
      setDisableButtonStatus(false)
    }
  }

  // Remove partner
  const removePartner = async () => {
    if (confirm("Are you sure you want to remove this partner?")) {
      // do stuff
      setButtonLoad(true)
      setDisableButton(true)
      try {
        const options = {
          method: "PUT",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/orders/${props?.match?.params?.id}/remove-partner`,
          options
        )
        const data = await response.json()
        console.log(data)
        console.log(data.message)
        toast.success(data["message"])
        setTimeout(() => {
          window.location.href = "/all-orders"
        }, 5000)
      } catch (error) {
        // do what
        toast.error(data["message"])
        setButtonLoad(false)
        setDisableButton(false)
      }
    } else {
      return false
    }
  }

  // Cancel Order
  const cancelOrder = async () => {
    console.log("cancel order")
    if (confirm("Are you sure you want to cancel this order?")) {
      // do stuff
      setButtonLoad(true)
      setDisableButton(true)
      try {
        const options = {
          method: "PUT",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/orders/${props?.match?.params?.id}/cancel`,
          options
        )
        const data = await response.json()
        console.log(data)
        console.log(data.message)
        toast.success(data["message"])
        setTimeout(() => {
          window.location.href = "/all-orders"
        }, 5000)
      } catch (error) {
        // do what
        toast.error(data["message"])
        setButtonLoad(false)
        setDisableButton(false)
      }
    } else {
      return false
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Order Details | Dilivva Admin</title>
        </MetaTags>
        <ToastContainer />
        {isLoading ? (
          <div className="container">
            <div className="row">
              <div className="col-md-2 skeleton skeleton-head p-3 mt-1 mb-3"></div>
              <div className="card">
                <div className="card-body">
                  <div className="row p-2">
                    <div className="col-md-6">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-5 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-7 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-3 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className="mb-4 d-flex justify-content-center align-items-center"
              style={{
                gap: "4px",
                padding: "7px 18px 7px 12px",
                width: "76px",
                borderRadius: "32px",
                border: "1px solid #F0F0F0",
                background: "#FFF",
                boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.04)",
                marginBottom: "8px",
              }}
            >
              <Link
                to="/all-orders"
                className="text-black d-flex align-items-center"
              >
                <FaChevronLeft size={16} />
                <div
                  style={{
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginLeft: "4px",
                  }}
                >
                  Back
                </div>
              </Link>
            </div>

            <ul className="nav nav-pills mb-3">
              <li>
                <a
                  className="nav-link active"
                  data-bs-toggle="pill"
                  href="#tab1"
                >
                  Important Details
                </a>
              </li>
              <li>
                <a className="nav-link" data-bs-toggle="pill" href="#tab2">
                  All Details
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="tab1">
                <div className="card">
                  <h4 className="package-detials">Order details</h4>
                  <div className="details-variant">
                    <div className="row mb-4">
                      <div className="col-md-4">
                        <h6 className="details-name">Variant</h6>
                        <h4 className="details-info">
                          {orders?.variant?.toString()?.toLowerCase()}
                        </h4>
                      </div>
                      <div className="col-md-4">
                        <h6 className="details-name">Current Location</h6>
                        <h4 className="details-info">
                          {orders?.current_location_type === "Sender" ||
                          orders?.current_location_type === "Recipient"
                            ? orders?.current_location_type
                            : orders?.current_phase === "center_to_center" ||
                              orders?.current_phase === "hub_to_sweeper" ||
                              orders?.current_phase === "partner_to_center" ||
                              orders?.current_phase === "sweeper_to_center"
                            ? `Origin ${orders?.current_location_type}`
                            : orders?.current_phase === "center_to_partner" ||
                              orders?.current_phase === "center_to_sweeper" ||
                              orders?.current_phase === "hub_to_recipient" ||
                              orders?.current_phase === "parner_to_recipient" ||
                              orders?.current_phase === "sweeper_to_hub"
                            ? `Destination ${orders?.current_location_type}`
                            : ""}
                        </h4>
                      </div>
                    </div>
                    {orders?.variant === "HUB_TO_HUB" && (
                      <div className="details-row mx-0 mb-4">
                        <div className="row">
                          <div className="col-md-4">
                            <h6 className="details-name">Origin hub</h6>
                            <h4 className="details-info">
                              {orders?.origin_hub?.name}
                            </h4>
                          </div>
                          <div className="col-md-4">
                            <h6 className="details-name">Origin hub contact</h6>
                            <h4 className="details-info">
                              {orders?.origin_hub?.contact_person?.phone}
                            </h4>
                          </div>
                          <div className="col-md-4">
                            <h6 className="details-name">Origin hub address</h6>
                            <h4 className="details-info">
                              {orders?.origin_hub?.address}
                            </h4>
                          </div>
                        </div>
                      </div>
                    )}
                    {orders?.variant === "HUB_TO_HUB" && (
                      <div className="details-row mx-0 mb-4">
                        <div className="row">
                          <div className="col-md-4">
                            <h6 className="details-name">Destination hub</h6>
                            <h4 className="details-info">
                              {orders?.destination_hub?.name}
                            </h4>
                          </div>
                          <div className="col-md-4">
                            <h6 className="details-name">
                              Destination hub contact
                            </h6>
                            <h4 className="details-info">
                              {orders?.destination_hub?.contact_person?.phone}
                            </h4>
                          </div>
                          <div className="col-md-4">
                            <h6 className="details-name">
                              Destination hub address
                            </h6>
                            <h4 className="details-info">
                              {orders?.destination_hub?.address}
                            </h4>
                          </div>
                        </div>
                      </div>
                    )}
                    {orders?.variant === "HUB_TO_DOOR" && (
                      <div className="details-row mx-0 mb-4">
                        <div className="row">
                          <div className="col-md-4">
                            <h6 className="details-name">Origin hub</h6>
                            <h4 className="details-info">
                              {orders?.origin_hub?.name}
                            </h4>
                          </div>
                          <div className="col-md-4">
                            <h6 className="details-name">Origin hub contact</h6>
                            <h4 className="details-info">
                              {orders?.origin_hub?.contact_person?.phone}
                            </h4>
                          </div>
                          <div className="col-md-4">
                            <h6 className="details-name">Origin hub address</h6>
                            <h4 className="details-info">
                              {orders?.origin_hub?.address}
                            </h4>
                          </div>
                        </div>
                      </div>
                    )}
                    {orders?.variant === "DOOR_TO_HUB" && (
                      <div className="details-row mx-0 mb-4">
                        <div className="row">
                          <div className="col-md-4">
                            <h6 className="details-name">Destination hub</h6>
                            <h4 className="details-info">
                              {orders?.destination_hub?.name}
                            </h4>
                          </div>
                          <div className="col-md-4">
                            <h6 className="details-name">
                              Destination hub contact
                            </h6>
                            <h4 className="details-info">
                              {orders?.destination_hub?.contact_person?.phone}
                            </h4>
                          </div>
                          <div className="col-md-4">
                            <h6 className="details-name">
                              Destination hub address
                            </h6>
                            <h4 className="details-info">
                              {orders?.destination_hub?.address}
                            </h4>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="details-row mx-0 mb-4">
                      <div className="row">
                        <div className="col-md-4">
                          <h6 className="details-name">Sender name</h6>
                          <h4 className="details-info">
                            {orders?.user?.full_name}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">Sender Phone</h6>
                          <h4 className="details-info">
                            {orders?.user?.phone}
                          </h4>
                        </div>
                        {orders?.variant === "DOOR_TO_HUB" && (
                          <div className="col-md-4">
                            <h6 className="details-name">Sender Address</h6>
                            <h4 className="details-info">
                              {orders?.pickup_address}
                            </h4>
                          </div>
                        )}
                        {orders?.variant === "DOOR_TO_DOOR" && (
                          <div className="col-md-4">
                            <h6 className="details-name">Sender Address</h6>
                            <h4 className="details-info">
                              {orders?.pickup_address}
                            </h4>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="details-row mx-0 mb-4">
                      <div className="row">
                        <div className="col-md-4">
                          <h6 className="details-name">Recipient name</h6>
                          <h4 className="details-info">
                            {orders?.recipient?.name}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">Recipient Phone</h6>
                          <h4 className="details-info">
                            {orders?.recipient?.phone}
                          </h4>
                        </div>
                        {orders?.variant === "HUB_TO_DOOR" && (
                          <div className="col-md-4">
                            <h6 className="details-name">Recipient Address</h6>
                            <h4 className="details-info">
                              {orders?.destination_address}
                            </h4>
                          </div>
                        )}
                        {orders?.variant === "DOOR_TO_DOOR" && (
                          <div className="col-md-4">
                            <h6 className="details-name">Recipient Address</h6>
                            <h4 className="details-info">
                              {orders?.destination_address}
                            </h4>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="details-row mx-0 mb-4">
                      <div className="row">
                        <div className="col-md-3">
                          <h6 className="details-name">Package size</h6>
                          <h4 className="details-info">
                            {orders?.package?.size}
                          </h4>
                        </div>
                        <div className="col-md-3">
                          <h6 className="details-name">Fragile</h6>
                          <h4 className="details-info">
                            {orders?.package?.is_fragile === 0 ? "No" : "Yes"}
                          </h4>
                        </div>
                        <div className="col-md-3">
                          <h6 className="details-name">Status</h6>
                          <h4
                            className="details-info"
                            style={{
                              color:
                                orders?.status === "completed"
                                  ? "#29CC97"
                                  : orders?.status === "accepted"
                                  ? "#29CC79"
                                  : orders?.status === "cancelled"
                                  ? "#DC1515"
                                  : "#FEC400",
                            }}
                          >
                            {orders?.status}
                          </h4>
                        </div>
                        <div className="col-md-3">
                          <h6 className="details-name">Price</h6>
                          <h4 className="details-info">₦{orders?.price}</h4>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`details-row mx-0 mb-4 ${
                        orders?.package?.note === null &&
                        orders?.recipient?.note === null
                          ? "p-0"
                          : ""
                      }`}
                    >
                      <div className="row">
                        {orders?.package?.note && (
                          <div className="col-md-6">
                            <h6 className="details-name">Pickup Note</h6>
                            <h4 className="details-info">
                              {orders?.package?.note}
                            </h4>
                          </div>
                        )}
                        {orders?.recipient?.note && (
                          <div className="col-md-6">
                            <h6 className="details-name">Recipient Note</h6>
                            <h4 className="details-info">
                              {orders?.recipient?.note}
                            </h4>
                          </div>
                        )}
                      </div>
                    </div>

                    {orders?.package?.images && (
                      <div className="row mb-4">
                        <h6 className="details-name">Product image(s)</h6>
                        <div className="col-md-3">
                          <div
                            className="package-img"
                            style={{
                              backgroundImage: `url(${
                                process.env.REACT_APP_IMAGE_URL
                              }/download/${encodeURIComponent(
                                orders?.package?.images[0]
                              )})`,
                            }}
                          ></div>
                        </div>
                        <div className="col-md-3">
                          <div
                            className="package-img"
                            style={{
                              backgroundImage: `url(${
                                process.env.REACT_APP_IMAGE_URL
                              }/download/${encodeURIComponent(
                                orders?.package?.images[1]
                              )})`,
                            }}
                          ></div>
                        </div>
                        <div className="col-md-3">
                          <div
                            className="package-img"
                            style={{
                              backgroundImage: `url(${
                                process.env.REACT_APP_IMAGE_URL
                              }/download/${encodeURIComponent(
                                orders?.package?.images[2]
                              )})`,
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="tab-pane fade show" id="tab2">
                <div className="card">
                  <h4 className="order-detials">Order details</h4>
                  {orders?.origin_hub && (
                    <div className="details-row">
                      <div className="row">
                        <div className="col-md-4">
                          <h6 className="details-name">Origin hub</h6>
                          <h4 className="details-info">
                            {orders?.origin_hub?.name}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">Origin hub contact</h6>
                          <h4 className="details-info">
                            {orders?.origin_hub?.contact_person?.phone}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">Origin hub Address</h6>
                          <h4 className="details-content">
                            {orders?.origin_hub?.address}
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}
                  {orders?.origin_center && (
                    <div className="details-row">
                      <div className="row">
                        <div className="col-md-4">
                          <h6 className="details-name">Origin center</h6>
                          <h4 className="details-info">
                            {orders?.origin_center?.name}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">
                            Origin Center phone-number
                          </h6>
                          <h4 className="details-info">
                            {orders?.origin_center?.contact_person?.phone}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">
                            Origin center Address
                          </h6>
                          <h4 className="details-content">
                            {orders?.origin_center?.address}
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}
                  {orders?.destination_center && (
                    <div className="details-row">
                      <div className="row">
                        <div className="col-md-4">
                          <h6 className="details-name">Destination center</h6>
                          <h4 className="details-info">
                            {orders?.destination_center?.name}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">
                            Destination center phone number
                          </h6>
                          <h4 className="details-info">
                            {orders?.destination_center?.contact_person?.phone}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">
                            Destination center Address
                          </h6>
                          <h4 className="details-content">
                            {orders?.destination_center?.address}
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}
                  {orders?.destination_hub && (
                    <div className="details-row">
                      <div className="row">
                        <div className="col-md-4">
                          <h6 className="details-name">Destination hub</h6>
                          <h4 className="details-info">
                            {orders?.destination_hub?.name}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">
                            Destination hub contact
                          </h6>
                          <h4 className="details-info">
                            {orders?.destination_hub?.contact_person?.phone}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">
                            Destination hub Address
                          </h6>
                          <h4 className="details-content">
                            {orders?.destination_hub?.address}
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="details-row">
                    <div className="row">
                      <div className="col-md-4">
                        <h6 className="details-name">Sender Name</h6>
                        <h4 className="details-info">
                          {orders?.user?.full_name}
                        </h4>
                      </div>
                      <div className="col-md-4">
                        <h6 className="details-name">Sender phone number</h6>
                        <h4 className="details-info">{orders?.user?.phone}</h4>
                      </div>
                      <div className="col-md-4">
                        <h6 className="details-name">Sender Address</h6>
                        <h4 className="details-content">
                          {orders?.pickup_address}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="details-row">
                    <div className="row">
                      <div className="col-md-4">
                        <h6 className="details-name">Recipient Name</h6>
                        <h4 className="details-info">
                          {orders?.recipient?.name}
                        </h4>
                      </div>
                      <div className="col-md-4">
                        <h6 className="details-name">Recipient phone number</h6>
                        <h4 className="details-info">
                          {orders?.recipient?.phone}
                        </h4>
                      </div>
                      <div className="col-md-4">
                        <h6 className="details-name">Recipient Address</h6>
                        <h4 className="details-content">
                          {orders?.destination_address}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="details-variant">
                    <div className="row">
                      <div className="col-md-3">
                        <h6 className="details-name">Variant</h6>
                        <h4 className="details-info">
                          {orders?.variant?.toString()?.toLowerCase()}
                        </h4>
                      </div>
                      <div className="col-md-3">
                        <h6 className="details-name">Current phase</h6>
                        <h4 className="details-content">
                          {orders?.current_phase}
                        </h4>
                      </div>
                      <div className="col-md-2">
                        <h6 className="details-name">Status</h6>
                        <h4
                          className="details-content"
                          style={{
                            color:
                              orders?.status === "completed"
                                ? "#29CC97"
                                : orders?.status === "accepted"
                                ? "#29CC79"
                                : orders?.status === "cancelled"
                                ? "#DC1515"
                                : "#FEC400",
                          }}
                        >
                          {orders?.status}
                        </h4>
                      </div>
                      <div className="col-md-4">
                        <h6 className="details-name">Payment</h6>
                        <h4
                          className="details-content"
                          style={{
                            color:
                              orders?.payment_status === "completed"
                                ? "#29CC97"
                                : orders?.payment_status === "cancelled"
                                ? "#DC1515"
                                : "#FEC400",
                          }}
                        >
                          {orders?.payment_status}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="details-variant">
                    <div className="row">
                      <div className="col-md-3">
                        <h6 className="details-name">Created by</h6>
                        <h4 className="details-content">
                          {orders?.creator?.name}
                        </h4>
                      </div>
                      <div className="col-md-3">
                        <h6 className="details-name">Date created</h6>
                        <h4 className="details-content">
                          {orders?.created_at}
                        </h4>
                      </div>
                      <div className="col-md-2">
                        <h6 className="details-name">Package size</h6>
                        <h4 className="details-content">
                          {orders?.package?.size}
                        </h4>
                      </div>
                      <div className="col-md-4">
                        <h6 className="details-name">Reference</h6>
                        <h4 className="details-content">{orders?.reference}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="details-variant">
                    <div className="row">
                      {orders?.pickup_time && (
                        <div className="col-md-3">
                          <h6 className="details-name">Pickup time</h6>
                          <h4 className="details-content">
                            {orders?.pickup_time}
                          </h4>
                        </div>
                      )}
                      <div className="col-md-3">
                        <h6 className="details-name">Price</h6>
                        <h4 className="details-content">₦{orders?.price}</h4>
                      </div>
                      <div className="col-md-3">
                        <h6 className="details-name">Fragile</h6>
                        <h4 className="details-content">
                          {orders?.package?.is_fragile === 0 ? "No" : "Yes"}
                        </h4>
                      </div>
                      <div className="col-md-3">
                        <h6 className="details-name">Current location</h6>
                        <h4 className="details-content">
                          {orders?.current_location_type === "Sender" ||
                          orders?.current_location_type === "Recipient"
                            ? orders?.current_location_type
                            : orders?.current_phase === "center_to_center" ||
                              orders?.current_phase === "hub_to_sweeper" ||
                              orders?.current_phase === "partner_to_center" ||
                              orders?.current_phase === "sweeper_to_center"
                            ? `Origin ${orders?.current_location_type}`
                            : orders?.current_phase === "center_to_partner" ||
                              orders?.current_phase === "center_to_sweeper" ||
                              orders?.current_phase === "hub_to_recipient" ||
                              orders?.current_phase === "parner_to_recipient" ||
                              orders?.current_phase === "sweeper_to_hub"
                            ? `Destination ${orders?.current_location_type}`
                            : ""}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <h4 className="package-detials">Package details</h4>
                  <div className="details-variant">
                    <div className="row mb-4">
                      <div className="col-md-3">
                        <h6 className="details-name">Label</h6>
                        <h4 className="details-content">
                          {orders?.package?.label}
                        </h4>
                      </div>
                      <div className="col-md-3">
                        <h6 className="details-name">Reference</h6>
                        <h4 className="details-content">
                          {orders?.package?.reference}
                        </h4>
                      </div>
                      <div className="col-md-3">
                        <h6 className="details-name">Date created</h6>
                        <h4 className="details-content">
                          {orders?.package?.created_at}
                        </h4>
                      </div>
                      <div className="col-md-3">
                        <h6 className="details-name">Size</h6>
                        <h4 className="details-content">
                          {orders?.package?.size}
                        </h4>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-3">
                        <h6 className="details-name">Fragile</h6>
                        <h4 className="details-info">
                          {orders?.package?.is_fragile === 0 ? "No" : "Yes"}
                        </h4>
                      </div>
                      {orders?.package?.note && (
                        <div className="col-md-3">
                          <h6 className="details-name">Pickup Note</h6>
                          <h4 className="details-info">
                            {orders?.package?.note}
                          </h4>
                        </div>
                      )}
                      {orders?.recipient?.note && (
                        <div className="col-md-3">
                          <h6 className="details-name">Recipient Note</h6>
                          <h4 className="details-info">
                            {orders?.recipient?.note}
                          </h4>
                        </div>
                      )}
                    </div>
                    {orders?.package?.images && (
                      <div className="row mb-4">
                        <h6 className="details-name">Product image(s)</h6>
                        <div className="col-md-3">
                          <div
                            className="package-img"
                            style={{
                              backgroundImage: `url(${
                                process.env.REACT_APP_IMAGE_URL
                              }/download/${encodeURIComponent(
                                orders?.package?.images[0]
                              )})`,
                            }}
                          ></div>
                        </div>
                        <div className="col-md-3">
                          <div
                            className="package-img"
                            style={{
                              backgroundImage: `url(${
                                process.env.REACT_APP_IMAGE_URL
                              }/download/${encodeURIComponent(
                                orders?.package?.images[1]
                              )})`,
                            }}
                          ></div>
                        </div>
                        <div className="col-md-3">
                          <div
                            className="package-img"
                            style={{
                              backgroundImage: `url(${
                                process.env.REACT_APP_IMAGE_URL
                              }/download/${encodeURIComponent(
                                orders?.package?.images[2]
                              )})`,
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                    <div className="row mb-4">
                      {orders?.package_recordings?.length !== 0 && (
                        <div className="col-md-6">
                          {orders?.package_recordings?.map(recording => (
                            <>
                              {recording?.pickup_video ? (
                                <>
                                  <span className="details-name">
                                    Pickup Video
                                  </span>
                                  <div className="ratio ratio-16x9 my-4">
                                    <iframe
                                      src={`${recording?.pickup_video?.replace(
                                        "'",
                                        ""
                                      )}/preview`}
                                      title="Pickup video"
                                      allowFullScreen
                                    ></iframe>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <span className="details-name">
                                    Delivery Video
                                  </span>
                                  <div className="ratio ratio-16x9 my-4">
                                    <iframe
                                      src={`${recording?.delivery_video?.replace(
                                        "'",
                                        ""
                                      )}/preview`}
                                      title="Delivery video"
                                      allowFullScreen
                                    ></iframe>
                                  </div>
                                </>
                              )}
                            </>
                          ))}
                        </div>
                      )}
                      {orders?.package_recording?.delivery_video && (
                        <div className="col-md-6">
                          <span className="details-name">Delivery Video</span>
                          <div className="ratio ratio-16x9 my-4">
                            <iframe
                              src={`${orders?.package_recording?.delivery_video.replace(
                                "'",
                                ""
                              )}/preview`}
                              title="Pickup video"
                              allowFullScreen
                            ></iframe>
                            "No Video"
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div>
                        {orders?.status !== "completed" && (
                          <button
                            className="m-3 btn btn-danger"
                            onClick={() => {
                              cancelOrder()
                            }}
                            disabled={disableButton}
                          >
                            Cancel Order
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {orders && orders?.trackingHistory?.length > 0 && (
                  <div className="card">
                    <h4 className="order-detials">Package location</h4>
                    <div className="order-tracking">
                      <div className="tracking-border">
                        {orders?.trackingHistory?.map((item, index) => (
                          <div className="main-tracking">
                            <div className="tacking-img">
                              <div className="w-5 h-5" key={index}>
                                {item?.completed_at ? (
                                  <img
                                    src={point}
                                    alt="check"
                                    width={20}
                                    height={20}
                                  />
                                ) : (
                                  <img
                                    src={points}
                                    alt="check"
                                    width={20}
                                    height={20}
                                  />
                                )}
                              </div>
                              <div
                                className={`${
                                  index === orders?.trackingHistory.length - 1
                                    ? ""
                                    : "tracking-line"
                                }
                                                      `}
                              ></div>
                            </div>

                            <div className="mb-6">
                              <p className="text-black text-[13px] font-normal leading-5">
                                {item?.description}
                              </p>
                              <p className="text-[#8E8E93] text-xs font-normal leading-4 mb-1.5">
                                {item?.completed_at}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div className="card">
                  <h4 className="package-detials">Sender details</h4>
                  <div className="details-variant">
                    <div className="row mb-4">
                      <div className="col-md-3">
                        <h6 className="details-name">Name</h6>
                        <h4 className="details-content">
                          {orders?.user?.full_name}
                        </h4>
                      </div>
                      <div className="col-md-3">
                        <h6 className="details-name">Email address</h6>
                        <h4 className="details-content">
                          {orders?.user?.email}
                        </h4>
                      </div>
                      <div className="col-md-3">
                        <h6 className="details-name">Phone number</h6>
                        <h4 className="details-content">
                          {orders?.user?.phone}
                        </h4>
                      </div>
                      <div className="col-md-3">
                        <h6 className="details-name">Date created</h6>
                        <h4 className="details-content">
                          {orders?.user?.created_at}
                        </h4>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-3">
                        <h6 className="details-name">Wallet</h6>
                        <h4 className="details-info">
                          ₦ {orders?.user?.wallet?.balance}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <h4 className="package-detials">Recipient details</h4>
                  <div className="details-variant">
                    <div className="row mb-4">
                      <div className="col-md-3">
                        <h6 className="details-name">Name</h6>
                        <h4 className="details-content">
                          {orders?.recipient?.name}
                        </h4>
                      </div>
                      {orders?.recipient?.email && (
                        <div className="col-md-3 mb-4">
                          <h6 className="details-name">Email address</h6>
                          <h4 className="details-content">
                            {orders?.recipient?.email}
                          </h4>
                        </div>
                      )}
                      <div className="col-md-3">
                        <h6 className="details-name">Phone number</h6>
                        <h4 className="details-content">
                          {orders?.recipient?.phone}
                        </h4>
                      </div>
                      <div className="col-md-3">
                        <h6 className="details-name">Date created</h6>
                        <h4 className="details-content">
                          {orders?.recipient?.created_at}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <h4 className="package-detials">Transaction</h4>
                  <div className="details-variant">
                    <div className="table-responsive">
                      <Table className="table-hover mt-2 mb-0">
                        <thead>
                          <tr>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>Reference</th>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders?.transactions?.map((transaction, index) => {
                            const {
                              uuid,
                              amount,
                              description,
                              reference,
                              created_at,
                              status,
                              type,
                            } = transaction
                            return (
                              <tr
                                key={uuid}
                                onClick={() =>
                                  setRedirect(`../transaction/${uuid}`)
                                }
                              >
                                <td>{amount}</td>
                                <td>{description}</td>
                                <td>{reference}</td>
                                <td
                                  style={{
                                    color:
                                      type === "credit"
                                        ? "#29CC97"
                                        : type === "debit"
                                        ? "#DC1515"
                                        : "#FEC400",
                                  }}
                                >
                                  {type}
                                </td>
                                <td>{created_at}</td>
                                <td
                                  style={{
                                    color:
                                      status === "success"
                                        ? "#29CC97"
                                        : status === "pending"
                                        ? "#FEC400"
                                        : "#DC1515",
                                  }}
                                >
                                  {status}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <h4 className="package-detials">Timeline</h4>
                  <div className="details-variant">
                    <div className="row mb-4">
                      {orders?.timeline?.created_at && (
                        <div className="col-md-3 mb-4">
                          <h6 className="details-name">Date created</h6>
                          <h4 className="details-content">
                            {orders?.timeline?.created_at}
                          </h4>
                        </div>
                      )}
                      {orders?.timeline?.confirmed_at && (
                        <div className="col-md-3 mb-4">
                          <h6 className="details-name">Date confirmed</h6>
                          <h4 className="details-content">
                            {orders?.timeline?.confirmed_at}
                          </h4>
                        </div>
                      )}
                      {orders?.timeline?.accepted_at && (
                        <div className="col-md-3 mb-4">
                          <h6 className="details-name">Date accepted</h6>
                          <h4 className="details-content">
                            {orders?.timeline?.accepted_at}
                          </h4>
                        </div>
                      )}
                      {orders?.timeline?.transit_started_at && (
                        <div className="col-md-3 mb-4">
                          <h6 className="details-name">Date transit started</h6>
                          <h4 className="details-content">
                            {orders?.timeline?.transit_started_at}
                          </h4>
                        </div>
                      )}
                      {orders?.timeline?.destination_reached_at && (
                        <div className="col-md-3 mb-4">
                          <h6 className="details-name">
                            Date destination reached
                          </h6>
                          <h4 className="details-content">
                            {orders?.timeline?.destination_reached_at}
                          </h4>
                        </div>
                      )}
                      {orders?.timeline?.released_at && (
                        <div className="col-md-3 mb-4">
                          <h6 className="details-name">Date released</h6>
                          <h4 className="details-content">
                            {orders?.timeline?.released_at}
                          </h4>
                        </div>
                      )}
                      {orders?.timeline?.confirmed_at && (
                        <div className="col-md-3 mb-4">
                          <h6 className="details-name">Date confirmed</h6>
                          <h4 className="details-content">
                            {orders?.timeline?.confirmed_at}
                          </h4>
                        </div>
                      )}
                      {orders?.timeline?.partner_paid_at && (
                        <div className="col-md-3 mb-4">
                          <h6 className="details-name">
                            Date partner was paid
                          </h6>
                          <h4 className="details-content">
                            {orders?.timeline?.partner_paid_at}
                          </h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {orders?.verification_code && (
                  <div className="card">
                    <h4 className="package-detials">Verification Code</h4>
                    <div className="details-variant">
                      <div className="row mb-4">
                        {orders?.verification_code?.send_code && (
                          <div className="col-md-3">
                            <span className="details-name">Send Code</span>
                            <h6 className="details-content">
                              {orders?.verification_code?.send_code}
                            </h6>
                          </div>
                        )}
                        {orders?.verification_code?.release_code && (
                          <div className="col-md-3">
                            <span className="details-name">Release Code</span>
                            <h6 className="details-content">
                              {orders?.verification_code?.release_code}
                            </h6>
                          </div>
                        )}
                        {orders?.verification_code?.transfer_code && (
                          <div className="col-md-3">
                            <span className="details-name">Transfer Code</span>
                            <h6 className="details-content">
                              {orders?.verification_code?.transfer_code}
                            </h6>
                          </div>
                        )}
                        {orders?.verification_code?.agent_release_code && (
                          <div className="col-md-3">
                            <span className="details-name">
                              Agent Release Code
                            </span>
                            <h6 className="details-content">
                              {orders?.verification_code?.agent_release_code}
                            </h6>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        {orders?.verification_code?.verification_codes && (
                          <>
                            {orders?.verification_code?.verification_codes
                              ?.center_to_center && (
                              <div className="col">
                                <span className="details-name">
                                  Center to center
                                </span>
                                <h6 className="details-content">
                                  {
                                    orders?.verification_code
                                      ?.verification_codes?.center_to_center
                                  }
                                </h6>
                              </div>
                            )}
                            {orders?.verification_code?.verification_codes
                              ?.center_to_partner && (
                              <div className="col">
                                <span className="details-name">
                                  Center to partner
                                </span>
                                <h6 className="details-content">
                                  {
                                    orders?.verification_code
                                      ?.verification_codes?.center_to_partner
                                  }
                                </h6>
                              </div>
                            )}
                            {orders?.verification_code?.verification_codes
                              ?.partner_to_center && (
                              <div className="col">
                                <span className="details-name">
                                  Partner to center
                                </span>
                                <h6 className="details-content">
                                  {
                                    orders?.verification_code
                                      ?.verification_codes?.partner_to_center
                                  }
                                </h6>
                              </div>
                            )}
                            {orders?.verification_code?.verification_codes
                              ?.sender_to_partner && (
                              <div className="col">
                                <span className="details-name">
                                  Sender to partner
                                </span>
                                <h6 className="details-content">
                                  {
                                    orders?.verification_code
                                      ?.verification_codes?.sender_to_partner
                                  }
                                </h6>
                              </div>
                            )}
                            {orders?.verification_code?.verification_codes
                              ?.partner_to_recipient && (
                              <div className="col">
                                <span className="details-name">
                                  Partner to recipient
                                </span>
                                <h6 className="details-content">
                                  {
                                    orders?.verification_code
                                      ?.verification_codes?.partner_to_recipient
                                  }
                                </h6>
                              </div>
                            )}
                            {orders?.verification_code?.verification_codes
                              ?.center_to_sweeper && (
                              <div className="col">
                                <span className="details-name">
                                  Center to sweeper
                                </span>
                                <h6 className="details-content">
                                  {
                                    orders?.verification_code
                                      ?.verification_codes?.center_to_sweeper
                                  }
                                </h6>
                              </div>
                            )}
                            {orders?.verification_code?.verification_codes
                              ?.hub_to_recipient && (
                              <div className="col">
                                <span className="details-name">
                                  Hub to recipient
                                </span>
                                <h6 className="details-content">
                                  {
                                    orders?.verification_code
                                      ?.verification_codes?.hub_to_recipient
                                  }
                                </h6>
                              </div>
                            )}
                            {orders?.verification_code?.verification_codes
                              ?.hub_to_sweeper && (
                              <div className="col">
                                <span className="details-name">
                                  Hub to sweeper
                                </span>
                                <h6 className="details-content">
                                  {
                                    orders?.verification_code
                                      ?.verification_codes?.hub_to_sweeper
                                  }
                                </h6>
                              </div>
                            )}
                            {orders?.verification_code?.verification_codes
                              ?.sender_to_hub && (
                              <div className="col">
                                <span className="details-name">
                                  Sender to hub
                                </span>
                                <h6 className="details-content">
                                  {
                                    orders?.verification_code
                                      ?.verification_codes?.sender_to_hub
                                  }
                                </h6>
                              </div>
                            )}
                            {orders?.verification_code?.verification_codes
                              ?.sweeper_to_center && (
                              <div className="col">
                                <span className="details-name">
                                  Sweeper to center
                                </span>
                                <h6 className="details-content">
                                  {
                                    orders?.verification_code
                                      ?.verification_codes?.sweeper_to_center
                                  }
                                </h6>
                              </div>
                            )}
                            {orders?.verification_code?.verification_codes
                              ?.sweeper_to_hub && (
                              <div className="col">
                                <span className="details-name">
                                  Sweeper to hub
                                </span>
                                <h6 className="details-content">
                                  {
                                    orders?.verification_code
                                      ?.verification_codes?.sweeper_to_hub
                                  }
                                </h6>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {orders?.partner ? (
                  <div className="card">
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <img
                          src={orders?.partner?.photo}
                          alt={orders?.partner?.first_name}
                          className="img-fluid rounded mb-4"
                          width={200}
                        />
                        <h4 className="text-green">
                          {orders?.partner?.first_name}{" "}
                          {orders?.partner?.last_name}
                        </h4>
                        <h6 className="details-content">
                          {orders?.partner?.email}
                        </h6>
                        <h6 className="details-content">
                          {orders?.partner?.phone}
                        </h6>
                      </div>
                      <div className="col-md-6">
                        <h6 className="details-content">
                          {orders?.partner?.address}
                        </h6>
                        <h6 className="details-content">
                          {orders?.partner?.state}
                        </h6>
                        <h6 className="details-content">
                          {orders?.partner?.date_of_birth}
                        </h6>
                        <h6 className="details-content">
                          {orders?.partner?.age} years
                        </h6>
                        <img
                          src={orders?.partner?.signature}
                          alt={orders?.partner?.first_name}
                          className="img-fluid sign-img rounded mb-4"
                        />
                        <h6
                          className="details-content"
                          style={{
                            color: "#059669",
                          }}
                        >
                          {orders?.partner?.verification_status}
                        </h6>
                        <div className="details-content-name">
                          {orders?.partner?.created_at}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h5 className="details-content-name">
                          Delivery Method
                        </h5>
                        <p>{orders?.partner?.delivery_method?.name}</p>
                        <img
                          src={orders?.partner?.delivery_method?.image}
                          alt="delivery-method"
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-md-6">
                        <h5 className="details-content-name mt-3">
                          partner Price
                        </h5>
                        <h4 className="text-green">₦{orders?.partner_price}</h4>
                      </div>
                      <div className="col-md-6">
                        {orders?.status === "accepted" && (
                          <button
                            className="change my-5"
                            onClick={() => {
                              removePartner()
                            }}
                            disabled={disableButton}
                          >
                            Remove Partner
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card">
                    <div className="details-variant">
                      <p className="details-content">
                        No partner has picked the order
                      </p>
                      <button
                        type="button"
                        className="send"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal2"
                        onClick={() => {
                          showModal(singleOrder)
                        }}
                      >
                        Assign Order
                      </button>
                      <div
                        className="modal fade"
                        id="exampleModal2"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Assign Package
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-12">
                                    <input
                                      type="text"
                                      placeholder="Search partner by name or email address"
                                      onChange={e => setSearch(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <Table className="table responsive table-hover mt-2 mb-0">
                                    <tbody>
                                      {partners
                                        ?.filter(partner => {
                                          if (search === "") {
                                            return partner
                                          } else if (
                                            partner?.first_name
                                              ?.toLowerCase()
                                              ?.includes(search.toLowerCase())
                                          ) {
                                            return partner
                                          } else if (
                                            partner?.last_name
                                              ?.toLowerCase()
                                              ?.includes(search?.toLowerCase())
                                          ) {
                                            return partner
                                          } else if (
                                            partner?.email
                                              ?.toLowerCase()
                                              ?.includes(search?.toLowerCase())
                                          ) {
                                            return partner
                                          }
                                        })
                                        ?.map(partner => {
                                          const {
                                            uuid,
                                            first_name,
                                            last_name,
                                            email,
                                            verification_status,
                                          } = partner
                                          return (
                                            <>
                                              {verification_status ===
                                                "approved" && (
                                                <tr key={uuid}>
                                                  <td>
                                                    {first_name} {last_name}{" "}
                                                    <br />
                                                    <small className="text-green">
                                                      {email}
                                                    </small>
                                                  </td>
                                                  <td>
                                                    <button
                                                      className="btn btn-outline-warning"
                                                      onClick={() => {
                                                        assignPartner(uuid)
                                                      }}
                                                      disabled={disableButton}
                                                    >
                                                      Assign{" "}
                                                      {buttonLoad && (
                                                        <div
                                                          className="spinner-border text-warning mx-2"
                                                          style={{
                                                            width: "15px",
                                                            height: "15px",
                                                            fontSize: "10px",
                                                          }}
                                                          role="status"
                                                        >
                                                          <span className="visually-hidden">
                                                            Loading...
                                                          </span>
                                                        </div>
                                                      )}
                                                    </button>
                                                  </td>
                                                </tr>
                                              )}
                                            </>
                                          )
                                        })}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="card">
                  <h4 className="package-detials">Sweeper details</h4>
                  <div className="details-variant">
                    {orders?.origin_sweeper && (
                      <div className="row mb-4">
                        <div className="col-md-4">
                          <h6 className="details-name">Origin sweeper name </h6>
                          <h4 className="details-content">
                            {orders?.origin_sweeper?.first_name}{" "}
                            {orders?.origin_sweeper?.last_name}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">
                            Origin sweeper number
                          </h6>
                          <h4 className="details-content">
                            {orders?.origin_sweeper?.phone}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">
                            Origin sweeper email{" "}
                          </h6>
                          <h4 className="details-content">
                            {orders?.origin_sweeper?.email}
                          </h4>
                        </div>
                      </div>
                    )}
                    {orders?.destination_sweeper && (
                      <div className="row mb-4">
                        <div className="col-md-4">
                          <h6 className="details-name">
                            Destination sweeper name
                          </h6>
                          <h4 className="details-content">
                            {orders?.destination_sweeper?.first_name}{" "}
                            {orders?.destination_sweeper?.last_name}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">
                            Destination sweeper number
                          </h6>
                          <h4 className="details-content">
                            {orders?.destination_sweeper?.phone}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">
                            Destination sweeper email
                          </h6>
                          <h4 className="details-content">
                            {orders?.destination_sweeper?.email}
                          </h4>
                        </div>
                      </div>
                    )}

                    {orders?.status !== "completed" && !showSweepers && (
                      <button
                        type="button"
                        className="send my-3"
                        onClick={() => {
                          setShowSweepers(true)
                        }}
                      >
                        Change Sweepers
                      </button>
                    )}
                    {showSweepers && (
                      <div className="row">
                        {orders?.variant === "HUB_TO_DOOR" ||
                        orders?.variant === "HUB_TO_HUB" ? (
                          <div className="col-md-6 mb-3 p-2">
                            <label htmlFor="partners" className="h5">
                              Select Origin Sweeper
                            </label>

                            <select
                              className="form-select shadow-none p-2"
                              aria-label="Default select example"
                              onChange={e => {
                                setChangeSweepers(false)
                                setOriginSweeperId(e.target.value)
                                console.log(e.target.value)
                              }}
                            >
                              <option>
                                {orders?.origin_sweeper?.first_name}{" "}
                                {orders?.origin_sweeper?.last_name}
                              </option>
                              {sweepers.map(sweeper => {
                                const { uuid, first_name, last_name } = sweeper
                                return (
                                  <option value={uuid} key={uuid}>
                                    {first_name} {last_name}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        ) : (
                          ""
                        )}
                        {orders?.variant === "DOOR_TO_HUB" ||
                          (orders?.variant === "HUB_TO_HUB" ? (
                            <div className="col-md-6 mb-3 p-2">
                              <label htmlFor="sweepers" className="h5">
                                Select Destination Sweeper
                              </label>

                              <select
                                className="form-select shadow-none p-2"
                                aria-label="Default select example"
                                onChange={e => {
                                  setChangeSweepers(false)
                                  setDestinationSweeperId(e.target.value)
                                  console.log(e.target.value)
                                }}
                              >
                                <option>
                                  {orders?.destination_sweeper?.first_name}{" "}
                                  {orders?.destination_sweeper?.last_name}
                                </option>
                                {sweepers.map(sweeper => {
                                  const { uuid, first_name, last_name } =
                                    sweeper
                                  return (
                                    <option value={uuid} key={uuid}>
                                      {first_name} {last_name}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          ) : (
                            ""
                          ))}
                        <div>
                          <button
                            className="send"
                            disabled={changeSweepers}
                            onClick={assignSweeper}
                          >
                            Update Sweeper
                            {buttonLoad && (
                              <div
                                className="spinner-border text-white mx-2"
                                style={{
                                  width: "17px",
                                  height: "17px",
                                  fontSize: "10px",
                                }}
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {orders?.coupon && (
                  <>
                    <h4 className="orders-head mb-4">Coupon</h4>
                    <div className="container">
                      <div className="row">
                        <div className="card">
                          <div className="card-body">
                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="details-name">
                                  Coupon Code
                                </span>
                                <h6 className="details">
                                  {orders?.coupon?.code}
                                </h6>
                              </div>
                              <div className="col-md-6">
                                <span className="details-name">
                                  Description
                                </span>
                                <h6 className="details">
                                  {orders?.coupon?.description}
                                </h6>
                              </div>
                              <div className="col-md-6">
                                <span className="details-name">
                                  Price Discount
                                </span>
                                <h6 className="details">
                                  {orders?.coupon?.price_discount}
                                </h6>
                              </div>
                              <div className="col-md-6">
                                <span className="details-name">Starts</span>
                                <h6 className="details">
                                  {orders?.coupon?.starts_at}
                                </h6>
                              </div>
                              <div className="col-md-6">
                                <span className="details-name">Expires</span>
                                <h6 className="details">
                                  {orders?.coupon?.expires_at}
                                </h6>
                              </div>
                              {orders?.coupon?.deactivated_at && (
                                <div className="col-md-6">
                                  <span className="details-name">
                                    Deactivated
                                  </span>
                                  <h6 className="details">
                                    ₦{orders?.coupon?.deactivated_at}
                                  </h6>
                                </div>
                              )}
                              <div className="col-md-6">
                                <span className="details-name">
                                  Date Created
                                </span>
                                <h6 className="details">
                                  {orders?.coupon?.created_at}
                                </h6>
                              </div>
                              <div className="col-md-6">
                                <span className="details-name">
                                  Date Updated
                                </span>
                                <h6 className="details">
                                  {orders?.coupon?.updated_at}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {orders?.pickup_contact && (
                  <>
                    <h4 className="mb-3">Pickup Contact</h4>
                    <div className="container">
                      <div className="row">
                        <div className="card">
                          <div className="card-body">
                            <div className="row p-2">
                              <div className="col">
                                <span className="details-name">Name</span>
                                <h6 className="details">
                                  {orders?.pickup_contact?.name}
                                </h6>
                              </div>
                              <div className="col">
                                <span className="details-name">Email</span>
                                <h6 className="details">
                                  {orders?.pickup_contact?.email}
                                </h6>
                              </div>
                              <div className="col">
                                <span className="details-name">Phone</span>
                                <h6 className="details">
                                  {orders?.pickup_contact?.phone}
                                </h6>
                              </div>
                              <div className="col">
                                <span className="details-name">
                                  Date Created
                                </span>
                                <h6 className="details">
                                  {orders?.pickup_contact?.created_at}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {orders?.alternate_recipient && (
                  <>
                    <h4 className="mb-3">Alternate Recipient</h4>
                    <div className="container">
                      <div className="row">
                        <div className="card">
                          <div className="card-body">
                            <div className="row p-2">
                              <div className="col-md-3">
                                <span className="details-name">Name</span>
                                <h6 className="details">
                                  {orders?.alternate_recipient?.name}
                                </h6>
                              </div>
                              <div className="col-md-3">
                                <span className="details-name">Phone</span>
                                <h6 className="details">
                                  {orders?.alternate_recipient?.phone}
                                </h6>
                              </div>
                              <div className="col-md-3">
                                <span className="details-name">Address</span>
                                <h6 className="details">
                                  {orders?.alternate_recipient?.address}
                                </h6>
                              </div>
                              <div className="col-md-3">
                                <span className="details-name">Notes</span>
                                <h6 className="details">
                                  {orders?.alternate_recipient?.notes}
                                </h6>
                              </div>
                              <div className="col-md-3">
                                <span className="details-name">LGA</span>
                                <h6 className="details">
                                  {
                                    orders?.alternate_recipient
                                      ?.local_government
                                  }
                                </h6>
                              </div>
                              <div className="col-md-3">
                                <span className="details-name">State</span>
                                <h6 className="details">
                                  {orders?.alternate_recipient?.state}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {orders?.status !== "completed" && (
                  <div className="card">
                    <h4 className="package-detials">Order Status</h4>
                    <div className="details-variant">
                      <div className="row p-2">
                        <h6>
                          Order Status:
                          <span
                            className="px-2"
                            style={{
                              color:
                                orders?.status === "completed"
                                  ? "#29CC97"
                                  : orders?.status === "accepted"
                                  ? "#29CC79"
                                  : orders?.status === "cancelled"
                                  ? "#DC1515"
                                  : "#FEC400",
                            }}
                          >
                            {orders?.status}
                          </span>
                        </h6>
                      </div>
                      <div className="row">
                        {orders?.variant === "DOOR_TO_DOOR" ||
                          (orders?.variant === "DOOR_TO_HUB" && (
                            <div className="col-md-6 mb-3 p-2">
                              <label
                                htmlFor="partners"
                                className="h5 text-secondary"
                              >
                                Select Origin Partner (optional)
                              </label>

                              <select
                                className="form-select shadow-none p-2"
                                aria-label="Default select example"
                                onChange={e => {
                                  setOriginPartner(e.target.value)
                                  console.log(e.target.value)
                                  setDisableButtonStatus(false)
                                }}
                              >
                                <option>Select Partners</option>
                                {partners.map(partner => {
                                  const { uuid, first_name, last_name } =
                                    partner
                                  return (
                                    <option value={uuid} key={uuid}>
                                      {first_name} {last_name}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          ))}
                        {orders?.variant === "DOOR_TO_DOOR" ||
                          (orders?.variant === "HUB_TO_DOOR" && (
                            <div className="col-md-6 mb-3 p-2">
                              <label
                                htmlFor="partners"
                                className="h5 text-secondary"
                              >
                                Select Destination Partner (optional)
                              </label>

                              <select
                                className="form-select shadow-none p-2"
                                aria-label="Default select example"
                                onChange={e => {
                                  setDestinationPartner(e.target.value)
                                  console.log(e.target.value)
                                  setDisableButtonStatus(false)
                                }}
                              >
                                <option>Select Partners</option>
                                {partners.map(partner => {
                                  const { uuid, first_name, last_name } =
                                    partner
                                  return (
                                    <option value={uuid} key={uuid}>
                                      {first_name} {last_name}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          ))}
                      </div>

                      <button
                        onClick={orderStatus}
                        className="btn btn-success mt-4"
                        disabled={disableButtonStatus}
                      >
                        Change Status
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default OrderDetails
